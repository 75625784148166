<h2>Специализированная грузовая авторазборка техники MAN предлагает запчасти в наличии и под заказ для Вашего MAN отличного качества и по приятным ценам. 

  Запасные части с европейских машин не старше 2007 года с небольшим пробегом. У официального дилера пройдена полная диагностика всех автомобилей на разбор и всех продаваемых узлов и агрегатов.
  
  Все разбираемые машины в наличии, большой ассортимент б/у запасных частей на модельные ряды MAN TGA, TGS, TGX.
  
  Предоставляем гарантию, отправляем запчасти в регионы.</h2>

  <section class="bu">
    <div *ngFor="let item of buCard; let i = index" class="bu__card">
      <img src="../../../assets/cabins_{{i}}.jpg" alt="{{item.alt}}">
      <p>{{item.title}}</p>
    </div>
  </section>

