import { BuComponent } from './component/bu/bu.component';
import { StorageComponent } from './component/storage/storage.component';
import { StocksComponent } from './component/stocks/stocks.component';
import { ContactsComponent } from './component/contacts/contacts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './component/main/main.component';


const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'storage', component: StorageComponent},
  {path: 'stocks', component: StocksComponent},
  {path: 'bu', component: BuComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
