import {Component} from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { MailService } from 'src/app/service/mail.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss']
})

export class MainComponent {

  form: FormGroup
  isDisabled = false
  isLoader: boolean = false

  constructor(private mail: MailService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      search: new FormControl(null),
      contacts: new FormControl(null, Validators.required),
      comment: new FormControl(null),
    })
  }

  submit(): void {

    if (this.form.invalid) {
      alert('Заполните Тел. или E-mail для обратной связи')
      return
    }

    this.isDisabled = true
    this.isLoader = true

    this.mail.sendMassege(this.form.value).subscribe(res => {
      console.log(res)
      this.form.reset()
      this.isDisabled = false
      this.isLoader = false
      this.toastr.success('Мы свяжимся с Вами в ближайшее время', 'Ваша заявка принята')
    })
    
  }
  
}