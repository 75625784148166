
<app-loader *ngIf="isLoader; else content"></app-loader>

<ng-template #content>
  <section class="search__wrapper">
      <h1>Поиск</h1>
      <p>Введите наименование или артикул</p>
      <input class="search browser-default" type="text" name="search" placeholder="наименование или артикул" (input)="onSearch($event)" #inputSearch>
  </section>

  <section *ngIf="isGoods ; else search" class="storage">
    <div *ngFor="let item of goods" class="storage__card">
      <div class="storage__card--main">
      <h3 class="storage__card--text">{{item.name}}</h3>
      <p class="storage__card--text">Артикул: {{item.id}}</p>
      </div>
      <div class="storage__card--remains">
        <p class="storage__card--text">Остаток: {{item.remains}}</p>
      </div>
      <button class="btn" (click)="onRequest(item)">Запрос цены</button>
    </div>
  </section>

  <ng-template #search>
    <div class="warning">
      <h2>Поиск не дал результатов</h2>
      <button class="btn" (click)="onClearSearch()">очистить поиск</button>
    </div>
  </ng-template>
</ng-template>

<app-request *ngIf="goodsService.isRequest" titleBox="Запрос цены" buttonReturn="вернуться на склад" stocks="0"></app-request>