import { GoodsService } from './../../service/goods.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Goods } from 'src/app/interface';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef
  storage: Goods[] = []
  goods: Goods[] = []
  isGoods: boolean = true
  isLoader: boolean = true
  valueSearch: string

  constructor(
    private http: HttpClient,
    public goodsService: GoodsService) { }

  ngOnInit() {
    this.http.get<Goods[]>('https://manru-97410.firebaseio.com//goods.json')
        .subscribe(response => {
          this.storage = response
          this.goods = response
          this.isLoader = false
        } )
  
  }

  onSearch(event: KeyboardEvent) {
    this.valueSearch = (<HTMLInputElement>event.target).value.trim().toLowerCase()

    const search_name = this.storage
    .filter((el) => el.name.toLowerCase()
    .search(this.valueSearch) == -1 ? false : true)

    const search_id = this.storage
    .filter((el) => el.id.toLowerCase()
    .search(this.valueSearch) == -1 ? false : true)

    this.goods = [...new Set( [...search_name, ...search_id])]

    this.goods.length ? this.isGoods = true : this.isGoods = false
  }

  
  onClearSearch() {
    this.inputSearch.nativeElement.value = null
    this.goods = [...this.storage]
    this.isGoods = true
  }

  onRequest(item: Goods): void {
    this.goodsService.setProduct(item)
    this.goodsService.changeShowComponent(true)
  }

}
