import { Goods, Stocks } from 'src/app/interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoodsService {

  product: Goods | Stocks
  isRequest: boolean = false

  constructor() { }

  public setProduct(product: Goods | Stocks) {
    this.product = product
  }

  public changeShowComponent(param: boolean) {
    this.isRequest = param
  }
}
