<app-loader *ngIf="isLoader; else content"></app-loader>

<ng-template #content>
  <section class="stocks">
    <div *ngFor="let item of stocks" class="stocks__card">

      <div *ngIf="item.combo; else usually">
      
          <h3 class="stocks__card--text">{{item.combo.name}}</h3>
          <div *ngFor="let el of item.combo.set">
            <p class="stocks__card--text stocks__card--border" >Артикул: {{el.id}}</p>
            <p class="stocks__card--text" >Цена розничная: {{el.cost}} ₽</p>
          </div>
          <div class="stocks__card--remains">
            <p class="stocks__card--text">Цена со скидкой: {{item.combo.price}} ₽</p>
          </div>
          <button class="btn" (click)="onRequest(item)">Получить информацию</button>
      </div>

      <ng-template #usually>
        <div class="stocks__card--main">
          <h3 class="stocks__card--text">{{item.name}}</h3>
          <p class="stocks__card--text" >Цена розничная: {{item.cost}} ₽</p>
          <p class="stocks__card--text">Артикул: {{item.id}}</p>
          </div>
          <div class="stocks__card--remains">
            <p class="stocks__card--text">Цена со скидкой: {{item.price}} ₽</p>
          </div>
          <button class="btn" (click)="onRequest(item)">Получить информацию</button>
      </ng-template>

    </div>
  </section>
</ng-template>

<app-request *ngIf="goodsService.isRequest" titleBox="Получить подробную информацию" buttonReturn="вернуться к акциям" stocks="1"></app-request>