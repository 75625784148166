<div class="contacts">
<h1>Контакты</h1>
<p>ООО «КТИ»</p>
<p>ООО «КенигТракИнтернэшнл»</p>
 <br>
<p>Юридический и фактический адрес:</p>
<p>238325 Калининградская обл., Гурьевский р-н, пос.Рощино, ул.Сельская, 2</p>
 <br>
<div class="data">
<p>ИНН:    391 750 50 08</p>
<p>КПП:    391 701 001</p>
<p>ОКПО:   60599919</p>
<p>ОГРН:   1093925027800</p>
<br>
<p>Р/сч:     407 028 101 000 000 717 97 (руб.)</p>
<p>Р.сч      407 029 780 000 000 717 97 (евро)</p>
<p>Банк:     Филиал «Европейский» ПАО «Банк Санкт-Петербург» г.Калининград</p>
<p>Кор/сч:   30101810927480000877</p>
<p>БИК:      042748877</p>
</div>
 <br>
<p>Директор  Лазарев Анатолий Николаевич</p>
</div>