import { Massege } from './../interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }


  sendMassege(massege: Massege) {
    return this.http.post('https://us-central1-manru-97410.cloudfunctions.net/api/mail', massege)
  }

  sendMassegeRequest(massege: any) {
    return this.http.post('https://us-central1-manru-97410.cloudfunctions.net/api/mailRequest', massege)
  }
}