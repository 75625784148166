<app-loader *ngIf="isLoader; else content"></app-loader>

<ng-template #content>
<div class="request">
  <h1>{{titleBox}}</h1>
  <div class="request__info">
    <p>{{product.name}}</p>
    <p *ngIf="product.id; else combo">Акртикуль: {{product.id}}</p>
    <ng-template #combo>
      <p *ngFor="let item of product.set">Акртикуль: {{item.id}}</p>
    </ng-template>
  </div>

  <form class="form" [formGroup]="form" (ngSubmit)="submit()">

        <div>
          <label for="">Тел. или E-mail</label>
          <input type="text" formControlName="contacts">

          <span *ngIf="form.get('contacts').invalid && form.get('contacts').touched">
            <small *ngIf="form.get('contacts').errors.required">Поле не может быть пустым</small>
          </span>
        </div>

        <div>
          <button class="btn" [disabled]="isDisabled" type="submit">отправить заявку</button>
        </div>
  </form>

  <button class="btn" (click)="onReturn()">{{buttonReturn}}</button>

</div>

<div class="blackout"></div>
</ng-template>