import { GoodsService } from './../../service/goods.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MailService } from 'src/app/service/mail.service';
import { Goods, Stocks } from 'src/app/interface';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  @Input() titleBox: string
  @Input() buttonReturn: string
  @Input() stocks: boolean

  form: FormGroup
  isDisabled = false
  isLoader: boolean = false
  product: Goods | Stocks | any

  constructor(
    private mail: MailService, 
    private toastr: ToastrService,
    public goodsService: GoodsService) {}

  ngOnInit(): void {
    this.product = this.goodsService.product

    this.form = new FormGroup({
      contacts: new FormControl(null, Validators.required),
    })
  }

  submit(): void {

    if (this.form.invalid) {
      alert('Заполните Тел. или E-mail для обратной связи')
      return
    }

    this.isDisabled = true
    this.isLoader = true

    let massege

    if (!!+this.stocks) {
      massege = {
        contacts: this.form.value.contacts,
        name: this.product.name,
        price: this.product.price
      }
      this.product.id 
      ? massege.id = this.product.id
      : massege.id = this.product.set.map((el: { id: string, cost: number }) => el.id)
    } else {
      massege = {
        contacts: this.form.value.contacts,
        name: this.product.name,
        id: this.product.id
      }
    }

    this.mail.sendMassegeRequest(massege).subscribe(res => {
      console.log(res)
      this.isDisabled = false
      this.isLoader = false
      this.onReturn()
      this.toastr.success('Мы свяжимся с Вами в ближайшее время', 'Ваша заявка принята')
    })
    
  }

  onReturn(): void {
    this.goodsService.changeShowComponent(false)
  }

}
