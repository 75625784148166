<header>
  <nav>
    <h1>MANru.ru</h1>
    
    <ul>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/']">Главная</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/storage']">Склад</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/stocks']">Акции</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/contacts']">Контакты</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/bu']">Б/У</a></li>
    </ul>
  </nav>

  <div (click)="toggleMenu()" class="hamburger">
    <span class="hamburger-line"></span>
    <span class="hamburger-line"></span>
    <span class="hamburger-line"></span>
  </div>

  <div (click)="toggleMenu()" *ngIf="openMenu" class="menu">
    <ul>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/']">Главная</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/storage']">Склад</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/stocks']">Акции</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/contacts']">Контакты</a></li>
      <li routerLinkActive="active"><a [routerLink]="['/bu']">Б/У</a></li>
    </ul>
  </div>
</header>