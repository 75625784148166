import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bu',
  templateUrl: './bu.component.html',
  styleUrls: ['./bu.component.scss']
})
export class BuComponent implements OnInit {

  buImg: Array<number> = [...new Array(10).keys()]
  
  buCard: Array<{alt: string, title: string}> = [
    {
      alt: 'MAN TGX 2008',
      title: 'Кабина MAN TGX 2008'
    },
    {
      alt: 'cabins MAN TGX 2008',
      title: 'Кабина MAN TGX 2008'
    },
    {
      alt: 'MAN-TGX-2008',
      title: 'Кабина MAN TGX 2008'
    },
    {
      alt: 'Кабина MAN TGS 2008',
      title: 'Кабина MAN TGS 2008'
    },
    {
      alt: 'cabins-MAN-TGS-2008',
      title: 'Кабина MAN TGS 2008'
    },
    {
      alt: 'cabins-MAN-TGA',
      title: 'Кабина MAN TGA'
    },
    {
      alt: 'cabins-MAN-TGX-2008',
      title: 'Кабина MAN TGX 2008'
    },
    {
      alt: 'Кабина MAN TGX 2008',
      title: 'Кабина MAN TGX 2008'
    },
    {
      alt: 'cabins-MAN-TGA',
      title: 'Кабина MAN TGA'
    },
    {
      alt: 'cabins-MAN-TGS-2008',
      title: 'Кабина MAN TGS 2008'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
