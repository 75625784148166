
<app-header></app-header>

<div class="page__wrapper">
  <router-outlet></router-outlet>
</div>
<div style="height: 30px;"></div>
<app-footer></app-footer>


