import { Component, OnInit } from '@angular/core';
import { Stocks } from 'src/app/interface';
import { GoodsService } from 'src/app/service/goods.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit {

  stocks: Stocks[] = []
  isLoader: boolean = true

  constructor(
    private http: HttpClient,
    public goodsService: GoodsService) { }

  ngOnInit() {
    this.http.get<Stocks[]>('https://manru-97410.firebaseio.com//stocks.json')
        .subscribe(response => {
          this.stocks = response
          this.isLoader = false

        } )
  }

  onRequest(item: Stocks): void {
    this.goodsService.setProduct(item.combo ? item.combo : item)
    this.goodsService.changeShowComponent(true)
  }

}
