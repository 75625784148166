<app-loader *ngIf="isLoader; else content"></app-loader>

<ng-template #content>
  <section>
    <div class="contacts">
      <div>
        <p>Тел. WhatsApp</p>
        <p>8 (921) 008 39 90</p>
      </div>
      <div>
        <p>E-mail</p>
        <p>info@manru.ru</p>
      </div>
    </div>
    
    <h1>Запчасти МАН, Неоплан, силовые установки МАН, оригинал, не оригинал, б/у</h1>
  
    <form class="form" [formGroup]="form" (ngSubmit)="submit()">

      <div>
        <label for="">WIN или №-запчасти</label>
        <input type="text" formControlName="search">
      </div>

      <div>
        <label for="">Тел. или E-mail</label>
        <input type="text" formControlName="contacts">

        <span *ngIf="form.get('contacts').invalid && form.get('contacts').touched">
          <small *ngIf="form.get('contacts').errors.required">Поле не может быть пустым</small>
        </span>
      </div>

      <div>
        <label for="">Комментарий</label>
        <textarea type="text" rows="3" formControlName="comment" placeholder="оригинал, не оригинал, б/у..."></textarea>
      </div>

      <div>
        <button class="btn" [disabled]="isDisabled" type="submit">отправить заявку</button>
      </div>
    </form>

    <div class="storage">
      <h2>В наличии на складе</h2>
      <button class="btn"><a [routerLink]="['/storage']">смотреть</a></button>
    </div>
  </section>

  <h2 class="stocks">Товары по акции</h2>
  <app-stocks></app-stocks>
  
</ng-template>
  